import React from 'react';

import {
  ChallengeIconsContainer,
  ChallengeInfoContainer,
  ChallengeTitlesContainer,
  NextUpChallengeSubtitle,
  NextUpCardContainer,
  NextUpChallengeTitle,
  NextUpStepTitle,
  StepDetailsContainer,
  StepNumber,
} from '../components';
import ChallengeBasicInformation from 'modules/home/challenge-basic-information';
import { StepSchedule } from 'models/Schedule';
import useStepCardInformation from 'hooks/useStepCardInformation';
import { useSelector } from 'react-redux';
import { selectContentsContentful } from '../../loading-screen/contentful/contents/selectors';
import { selectContents } from '../../loading-screen/contents/selectors';
import { NextChallengeContainerBackground } from 'modules/challenge/components';

type ChallengeCardProps = {
  step: StepSchedule;
  onClick: () => void;
  className?: string;
};

const NextUpCard = ({ step, onClick, className }: ChallengeCardProps) => {
  const [challengeSubtitle, stepNumber] = useStepCardInformation(step);

  const contents = useSelector(selectContents);
  const challengeUser = contents[step.challengeId || ''];

  const contentsContentful = useSelector(selectContentsContentful);
  const challengeContentful = contentsContentful[challengeUser?.contentfulId];
  const challengeLocation = challengeContentful?.location;

  return (
    <NextUpCardContainer
      className={className}
      imageUrl={step.challengeImage}
      onClick={onClick}>
      <NextUpChallengeSubtitle>{challengeSubtitle}</NextUpChallengeSubtitle>
      <ChallengeInfoContainer>
        <NextChallengeContainerBackground>
          <ChallengeTitlesContainer>
            <NextUpChallengeTitle>{step.challengeTitle}</NextUpChallengeTitle>
            <StepDetailsContainer>
              <NextUpStepTitle>{step.stepTitle}</NextUpStepTitle>
              <StepNumber>{stepNumber}</StepNumber>
            </StepDetailsContainer>
          </ChallengeTitlesContainer>

          <ChallengeIconsContainer>
            <ChallengeBasicInformation
              time={step.stepDuration}
              xp={step.stepPoints}
              location={step.location || challengeLocation || ''}
              bonusChallenge={challengeContentful?.bonusChallenge}
            />
          </ChallengeIconsContainer>
        </NextChallengeContainerBackground>
      </ChallengeInfoContainer>
    </NextUpCardContainer>
  );
};

export default NextUpCard;
