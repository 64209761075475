import React, { ChangeEvent, useCallback } from 'react';
import { Document } from '@contentful/rich-text-types';

import { useDispatch, useSelector } from 'react-redux';
import { uploadResponse } from 'modules/applet-upload/actions';
import { selectAppletById } from 'modules/applet-upload/selectors';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  GroupAppletContainer,
  InlineAppletInput,
  InlineAppletText,
} from '../components';
import useExistingApplet from 'hooks/useExistingApplet';

type InlineTextAppletProps = {
  appletId: string;
  prompt?: Document;
  withoutContainer?: boolean;
};

const InlineTextApplet = ({
  appletId,
  prompt,
  withoutContainer = false,
}: InlineTextAppletProps) => {
  const dispatch = useDispatch();
  const renderPrompt = useContentfulRenderer(prompt, {
    additionalStyles: { paragraph: { color: '#8E8E93' } },
  });
  const appletToUpload = useSelector(selectAppletById(appletId));
  const disabled = useIsAppletDisabled();
  const id = useExistingApplet(appletId);

  const onChangeText = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        uploadResponse({
          applet: {
            id,
            appletId,
            appletType: 'inline-text',
            text: evt.target.value,
          },
          modified: true,
        }),
      );
    },
    [dispatch, id, appletId],
  );

  return (
    <>
      {disabled ? (
        withoutContainer ? (
          <>
            {renderPrompt}
            <InlineAppletText>{appletToUpload?.text}</InlineAppletText>
          </>
        ) : (
          <GroupAppletContainer>
            {renderPrompt}
            <InlineAppletText>{appletToUpload?.text}</InlineAppletText>
          </GroupAppletContainer>
        )
      ) : (
        <GroupAppletContainer>
          {renderPrompt}
          <InlineAppletInput
            rows={5}
            value={appletToUpload?.text}
            onChange={onChangeText}
            disabled={disabled}
          />
        </GroupAppletContainer>
      )}
    </>
  );
};

export default InlineTextApplet;
