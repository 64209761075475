import React, { ReactNode } from 'react';
import { MenuItem } from '@material-ui/core';

import { IMadlibInputFields } from 'models/contentful';
import { TextArea } from 'components/components';
import {
  MadlibInputShort,
  MadlibNonEditablePlaceholderText,
  MadlibNonEditableText,
  MadlibSelection,
  MadlibSelectionPlaceholder,
} from './components';

type MadlibInputProps = {
  inputType: IMadlibInputFields['type'];
  placeholder?: string;
  isMadlibEditMode?: boolean;
  options?: ReactNode;
  value: string;
  onValueChange?: (
    event: React.ChangeEvent<
      | {
        name?: string | undefined;
        value: unknown;
      }
      | HTMLTextAreaElement
      | HTMLInputElement
    >,
  ) => void;
  disabled?: boolean;
};

const MadlibInput = ({
  inputType,
  placeholder,
  isMadlibEditMode,
  options,
  value,
  onValueChange,
  disabled,
}: MadlibInputProps) => {
  return isMadlibEditMode ? (
    <>
      {inputType === 'selection' ? (
        <MadlibSelection
          value={value}
          onChange={onValueChange}
          variant="standard"
          disableUnderline
          displayEmpty
          disabled={disabled}>
          {placeholder ? (
            <MenuItem disabled value="">
              <MadlibSelectionPlaceholder>
                {placeholder}
              </MadlibSelectionPlaceholder>
            </MenuItem>
          ) : null}
          {options}
        </MadlibSelection>
      ) : null}
      {inputType === 'short-text' ? (
        <MadlibInputShort
          value={value}
          onChange={onValueChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : null}
      {inputType === 'long-text' ? (
        <TextArea
          id="area-madlib"
          value={value}
          onChange={onValueChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : null}
    </>
  ) : (value ?
    <MadlibNonEditableText value={value}>{` ${value} `}</MadlibNonEditableText> :
    <span>
      {' '}<MadlibNonEditablePlaceholderText>{placeholder}</MadlibNonEditablePlaceholderText>{' '}
    </span>
  );
};

export default MadlibInput;
