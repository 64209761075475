import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import { selectArtifactByContentfulIdAndWeek } from '../../../../modules/home/playlist/selectors';
import { selectSelectedWeekId } from '../../../../modules/loading-screen/weeks/selectors';
import PlaylistModal from '../../../../modules/modal/PlaylistModal';
import ConfirmModal from '../../../../modules/modal/ConfirmModal';
import { Artifact } from 'modules/home/playlist/typings';
import {
  Link,
  LinkProps,
  LinkText,
} from 'components/contentful-renders/components';

type PlaylistArtifactLinkProps = BlockNodeProps & LinkProps;

const PlaylistArtifactLink = ({
  node,
  children,
  ...linkProps
}: PlaylistArtifactLinkProps) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);

  const selectedWeekId = useSelector(selectSelectedWeekId);

  const artifact = useSelector(
    selectArtifactByContentfulIdAndWeek(
      node.data.target.sys.id,
      selectedWeekId,
    ),
  );

  const openPlaylistModal = useCallback(() => {
    setPlaylistModalOpen(true);
  }, []);
  const closePlaylistModal = useCallback(() => {
    setPlaylistModalOpen(false);
  }, []);
  const openConfirmModal = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);
  const closeConfrimModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const handleLinkClick = useCallback(() => {
    if (artifact?.type === 'reading') {
      openPlaylistModal();
    } else {
      if (!artifact?.completed) {
        openConfirmModal();
      }
    }
  }, [artifact, openConfirmModal, openPlaylistModal]);

  const handlePlaylistModalClose = useCallback(
    (artifact: Artifact | undefined) => {
      closePlaylistModal();
      if (!artifact?.completed) {
        openConfirmModal();
      }
    },
    [closePlaylistModal, openConfirmModal],
  );

  return (
    <>
      {artifact ? (
        <>
          <PlaylistModal
            open={playlistModalOpen}
            close={handlePlaylistModalClose}
            artifact={artifact}
          />
          <ConfirmModal
            open={confirmModalOpen}
            close={closeConfrimModal}
            artifact={artifact}
          />
          {artifact.type === 'reading' ? (
            <LinkText onClick={handleLinkClick} {...linkProps}>
              {children}
            </LinkText>
          ) : (
            <Link
              {...linkProps}
              href={artifact?.link ?? '#'}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkClick}>
              {children}
            </Link>
          )}
        </>
      ) : null}
    </>
  );
};

export default PlaylistArtifactLink;
