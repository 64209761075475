import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';

import {
  selectSelectedContentPostInitialValues,
  selectSelectedContentPostValidation,
  selectSelectedContentId,
  selectSelectedContentIsCompleted,
  selectSelectedContentPostReflect,
  selectSelectedContentPostFileUpload,
  selectSelectedContentPostButtonText,
  selectSelectedContentPostButtonColor,
  selectSelectedContentPostId,
  selectSelectedContentPostReflectPlaceholder,
} from 'modules/loading-screen/contents/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import AppUpload, { UploadFile } from 'components/applets/app-upload';
import localizedStrings from 'localization';
import { submitPost } from 'modules/loading-screen/contents/actions';
import {
  selectAppletAnswers,
  selectHasUserEmptyApplets,
} from 'modules/applet-upload/selectors';
import { AppDispatch } from 'redux/typings';
import {
  CelebrateFileUploadContainer,
  CelebrateFileUploadText,
  CelebrateForm,
  CelebrateErrorMessage,
  CelebrateFormTextArea,
  SectionContainer,
  CelebrateButton,
  StepCompletionError,
} from '../components';
import Congratulations from '../congratulations';
import { selectStepDate } from 'modules/loading-screen/schedules/selectors';
import { selectSelectedWeekId } from 'modules/loading-screen/weeks/selectors';
import SubmittedApplets from './SubmittedApplets';
import useFileUploadStatus from 'hooks/useFileUploadStatus';
import useActionStatus from 'hooks/useActionStatus';
import Share from '../share';
import { selectIsSampleQuest } from '../../loading-screen/quests/selectors';
import { openCelebrateModal, closeCelebrateModal } from '../../modal/actions';
import { selectIsCelebrateModalOpen } from '../../modal/selectors';

const Celebrate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasPressedSubmit, setHasPressedSubmit] = useState(false);
  const formikRef = useRef<FormikProps<any>>(null);
  const [isSubmittingPost, hasSubmittedPost] = useActionStatus(submitPost);
  const challengeId = useSelector(selectSelectedContentId);
  const isChallengeCompleted = useSelector(selectSelectedContentIsCompleted);
  const reflectDoc = useSelector(selectSelectedContentPostReflect);
  const reflectPlaceholder = useSelector(
    selectSelectedContentPostReflectPlaceholder,
  );
  const fileUploadDoc = useSelector(selectSelectedContentPostFileUpload);
  const postInitialValues = useSelector(selectSelectedContentPostInitialValues);
  const postValidationSchema = useSelector(selectSelectedContentPostValidation);
  const postButtonText = useSelector(selectSelectedContentPostButtonText);
  const postButtonColor = useSelector(selectSelectedContentPostButtonColor);
  const appletsAnswers = useSelector(selectAppletAnswers);
  const reflect = useContentfulRenderer(reflectDoc as any);
  const fileUpload = useContentfulRenderer(fileUploadDoc as any);
  const postId = useSelector(selectSelectedContentPostId);
  const postScheduledDay = useSelector(selectStepDate(postId));
  const weekId = useSelector(selectSelectedWeekId);
  const hasEmptyApplets = useSelector(selectHasUserEmptyApplets);
  const isSampleQuest = useSelector(selectIsSampleQuest);
  const isCelebrateModalOpen = useSelector(selectIsCelebrateModalOpen);

  const [isUploading, uploadingText, hasFailed, errorMessage] =
    useFileUploadStatus();

  const onSubmit = useCallback(
    (values: { reflectAnswers: string; file: UploadFile | undefined }) => {
      if (hasEmptyApplets) {
        alert(localizedStrings.completeApplets);
        return;
      }
      if (postId && weekId) {
        if (!postScheduledDay) {
          alert(
            'Warning!!! The step you are trying to complete seems to not have been scheduled. Please contact the admin. You will be able to complete it nonetheless.',
          );
        }

        dispatch(
          submitPost({
            appletsAnswers,
            challengeId,
            postId,
            weekId,
            postScheduledDay,
            submission: values,
          }),
        );
        setHasPressedSubmit(true);
      }
    },
    [
      hasEmptyApplets,
      postId,
      weekId,
      postScheduledDay,
      dispatch,
      challengeId,
      appletsAnswers,
    ],
  );

  const openCongratulations = useCallback(() => {
    dispatch(openCelebrateModal());
  }, [dispatch]);

  const closeCongratulations = useCallback(() => {
    dispatch(closeCelebrateModal());
  }, [dispatch]);

  useEffect(() => {
    if (hasPressedSubmit && hasSubmittedPost) {
      formikRef.current?.resetForm();
      openCongratulations();
    }
  }, [hasPressedSubmit, hasSubmittedPost, history, openCongratulations]);

  return (
    <>
      <Congratulations
        show={isCelebrateModalOpen}
        close={closeCongratulations}
      />
      <SectionContainer>
        <SubmittedApplets />
        <Formik
          initialValues={postInitialValues}
          onSubmit={onSubmit}
          validationSchema={postValidationSchema}
          innerRef={formikRef}
          enableReinitialize>
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            handleBlur,
            submitCount,
            errors,
          }) => (
            <>
              <CelebrateForm onSubmit={handleSubmit}>
                {reflect ? (
                  <>
                    {reflect}
                    <CelebrateFormTextArea
                      rows={5}
                      name="reflectAnswers"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reflectAnswers}
                      placeholder={reflectPlaceholder}
                      disabled={isChallengeCompleted}
                    />
                    {submitCount > 0 && errors.file && (
                      <CelebrateErrorMessage>
                        {errors.reflectAnswers}
                      </CelebrateErrorMessage>
                    )}
                  </>
                ) : null}
                {fileUpload ? (
                  <CelebrateFileUploadContainer>
                    <CelebrateFileUploadText>
                      {fileUpload}
                    </CelebrateFileUploadText>
                    <AppUpload
                      value={values.file}
                      onChangeFile={(file) => setFieldValue('file', file)}
                      disabled={isChallengeCompleted}
                    />
                    {submitCount > 0 && errors.file && (
                      <CelebrateErrorMessage>
                        {errors.file}
                      </CelebrateErrorMessage>
                    )}
                  </CelebrateFileUploadContainer>
                ) : null}
                {!isChallengeCompleted ? (
                  <>
                    {hasFailed ? (
                      <StepCompletionError>{errorMessage}</StepCompletionError>
                    ) : null}
                    <CelebrateButton
                      small
                      type="submit"
                      backgroundColor={postButtonColor}
                      isLoading={isSubmittingPost}>
                      {isUploading ? uploadingText : postButtonText}
                    </CelebrateButton>
                  </>
                ) : null}
              </CelebrateForm>
              {isChallengeCompleted && !isSampleQuest ? (
                <Share withShareMessage />
              ) : null}
            </>
          )}
        </Formik>
      </SectionContainer>
    </>
  );
};

export default Celebrate;
