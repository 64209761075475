import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';

import { IMadlibInputFields } from 'models/contentful';
import MadlibInput from 'components/madlib-input';
import { selectAppletById, selectAppletByInputId } from 'modules/applet-upload/selectors';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import {
  updateMadlibAnswer,
  updateQuizQuestionValue,
} from 'modules/applet-upload/actions';
import { MadlibSelectionOption } from 'components/contentful-renders/components';
import { QuizQuestionAnswerType } from '../../../../../models/QuizQuestionAnswerType';

type MadlibInputEditableProps = {
  appletId?: string;
  inputId: string;
  inputType: IMadlibInputFields['type'];
  options?: IMadlibInputFields['options'];
  placeholder?: string;
  isMadlibEditMode?: boolean;
  madlibAppletContentfulId?: string;
  quizAppletContentfulId?: string;
  quizContentfulId?: string;
  quizSectionContentfulId?: string;
  quizQuestionContentfulId?: string;
};

const MadlibInputEditable = ({
  appletId = '',
  inputId,
  inputType,
  options,
  placeholder,
  isMadlibEditMode,
  madlibAppletContentfulId,
  quizAppletContentfulId,
  quizContentfulId,
  quizSectionContentfulId,
  quizQuestionContentfulId,
}: MadlibInputEditableProps) => {
  const dispatch = useDispatch();
  const modifiedApplet = useSelector(selectAppletById(quizAppletContentfulId || appletId))

  const modifiedAppletAnswer = useSelector(selectAppletByInputId(quizAppletContentfulId || appletId, inputId));

  const disabled = useIsAppletDisabled();
  const onValueChangeMadlibApplet = useCallback(
    (
      event: React.ChangeEvent<
        | {
          name?: string;
          value: unknown;
        }
        | HTMLTextAreaElement
        | HTMLInputElement
      >,
    ) => {
      dispatch(
        updateMadlibAnswer({
          applet: {
            id: modifiedApplet?.id,
            appletId,
            inputId,
            value: event.target.value as string,
          },
          modified: true,
        }),
      );
    },
    [modifiedApplet, appletId, dispatch, inputId],
  );

  const onValueChangeQuizApplet = useCallback(
    (
      event: React.ChangeEvent<
        | {
          name?: string | undefined;
          value: unknown;
        }
        | HTMLTextAreaElement
        | HTMLInputElement
      >,
    ) => {
      if (
        madlibAppletContentfulId &&
        quizAppletContentfulId &&
        quizContentfulId &&
        quizSectionContentfulId &&
        quizQuestionContentfulId
      ) {
        dispatch(
          updateQuizQuestionValue({
            applet: {
              id: modifiedApplet?.id,
              appletId: quizAppletContentfulId,
              contentfulId: quizQuestionContentfulId,
              quizContentfulId: quizContentfulId,
              quizSectionContentfulId: quizSectionContentfulId,
              madlibAppletContentfulId,
              answer: undefined,
              answerType: QuizQuestionAnswerType.MADLIB,
              madlibInputValue: event.target.value as string,
              madlibInputContentfulId: inputId,
            },
            modified: true,
          }),
        );
      }
    },
    [
      dispatch,
      inputId,
      madlibAppletContentfulId,
      modifiedApplet?.id,
      quizAppletContentfulId,
      quizContentfulId,
      quizQuestionContentfulId,
      quizSectionContentfulId,
    ],
  );

  const onValueChange = useCallback(
    (
      event: React.ChangeEvent<
        | {
          name?: string | undefined;
          value: unknown;
        }
        | HTMLTextAreaElement
        | HTMLInputElement
      >,
    ) => {
      if (!quizAppletContentfulId) {
        onValueChangeMadlibApplet(event);
      } else {
        onValueChangeQuizApplet(event);
      }
    },
    [
      onValueChangeMadlibApplet,
      onValueChangeQuizApplet,
      quizAppletContentfulId,
    ],
  );

  const renderedOptions = useMemo(
    () =>
      options?.map((opt) => (
        <MenuItem key={opt} value={opt}>
          <MadlibSelectionOption disabled={disabled}>
            {opt}
          </MadlibSelectionOption>
        </MenuItem>
      )),
    [disabled, options],
  );

  const value = useMemo(() => {
    if (!quizAppletContentfulId) {
      if (modifiedAppletAnswer !== undefined && modifiedAppletAnswer.length === 0) {
        return ''
      }
      return modifiedAppletAnswer || ''
    } else if (quizQuestionContentfulId) {
      return (
        modifiedApplet?.quizInformation?.questionsMap?.[
          quizQuestionContentfulId
        ]?.madlibAnswers?.[inputId]?.answer ?? ''
      );
    } else return '';
  }, [
    quizAppletContentfulId,
    modifiedAppletAnswer,
    modifiedApplet?.quizInformation?.questionsMap,
    inputId,
    quizQuestionContentfulId,
  ]);

  return (
    <MadlibInput
      inputType={inputType}
      onValueChange={onValueChange}
      value={value}
      disabled={disabled}
      isMadlibEditMode={isMadlibEditMode}
      placeholder={placeholder}
      options={renderedOptions}
    />
  );
};

export default MadlibInputEditable;
