import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slider, withStyles } from '@material-ui/core';

import { updateQuizQuestionValue } from 'modules/applet-upload/actions';
import { IQuizQuestion } from 'models/contentful';
import { selectQuizQuestionValue } from 'modules/applet-upload/selectors';
import { selectChallengeQuizQuestion } from 'modules/loading-screen/contents/selectors';
import { selectQuestQuizQuestion } from 'modules/applets/selectors';
import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import { QuizQuestionAnswerType } from 'models/QuizQuestionAnswerType';

type QuizQuestionSliderListProps = {
  appletId: string;
  quizQuestionContentfulId: string;
  quizSectionContentfulId: string;
  question: IQuizQuestion;
  disabled: boolean;
};

const QuizQuestionSliderList = ({
  appletId: appletContentfulId,
  quizQuestionContentfulId,
  quizSectionContentfulId,
  question,
  disabled,
}: QuizQuestionSliderListProps) => {
  const dispatch = useDispatch();

  const { id: appletUserId, quizQuestions, quiz } =
    useQuizChallengeLogic(appletContentfulId);

  const questionToUploadValue = useSelector(
    selectQuizQuestionValue(appletContentfulId, question.sys.id),
  );
  const challengeQuestionValue = useSelector(
    selectChallengeQuizQuestion(appletContentfulId, question.sys.id),
  );
  const questQuestionValue = useSelector(
    selectQuestQuizQuestion(quizQuestionContentfulId, question.sys.id),
  );

  let value = useMemo(() => {
    if (quizQuestions) {
      return quizQuestions.find(
        (q) => q.quizQuestionContentfulId === question.sys.id,
      )?.value;
    }
    return (
      questionToUploadValue?.value ??
      challengeQuestionValue?.value ??
      questQuestionValue?.value ??
      question.fields.minimumValue
    );
  }, [
    questionToUploadValue,
    challengeQuestionValue,
    questQuestionValue,
    question,
    quizQuestions,
  ]);

  const onValueChange = useCallback(
    (event, val) => {
      dispatch(
        updateQuizQuestionValue({
          applet: {
            id: appletUserId,
            appletId: appletContentfulId,
            quizContentfulId: quiz!.sys.id,
            quizSectionContentfulId,
            contentfulId: question.sys.id,
            value: val,
            answerType: QuizQuestionAnswerType.SLIDER,
          },
          modified: true,
        }),
      );
    },
    [
      appletContentfulId,
      appletUserId,
      dispatch,
      question.sys.id,
      quizSectionContentfulId,
      quiz
    ],
  );

  const marks = useMemo(
    () =>
      Array.from(Array(question.fields.maximumValue).keys()).map((val) => ({
        value: val + 1,
        label: val + 1,
      })),
    [question.fields.maximumValue],
  );

  return (
    <>
      <QuizSlider
        value={value}
        valueLabelDisplay="off"
        step={1}
        marks={marks}
        min={question.fields.minimumValue}
        max={question.fields.maximumValue}
        onChange={onValueChange}
        disabled={disabled}
      />
    </>
  );
};

export const QuizSlider = withStyles({
  root: {
    color: '#F4BF5D',
    width: '25rem',
    maxWidth: '100%',
    marginBottom: '3rem',
  },
  thumb: {
    backgroundColor: '#fff',
    border: '0.44rem solid currentColor',
    height: '1.33rem',
    width: '1.33rem',
    marginTop: '-0.55rem',
    marginLeft: '-0.66rem',
    '&:focus, &:hover': {
      boxShadow: 'inherit',
    },
    '&:active': {
      height: '1.5rem',
      width: '1.5rem',
      marginTop: '-0.62rem',
      marginLeft: '-0.75rem',
    },
  },
  markLabel: {
    marginTop: 10,
  },
  disabled: {
    '& .MuiSlider-thumb': {
      height: '1.33rem',
      width: '1.33rem',
      marginTop: '-0.55rem',
      marginLeft: '-0.66rem',
    },
  },
})(Slider);

export default QuizQuestionSliderList;
