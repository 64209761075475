import React, { HTMLAttributes, useCallback, useRef, useEffect } from 'react';

import {
  AUDIO_MIMETYPE,
  IMAGE_MIMETYPE,
  PDF_MIMETYPE,
  TEXT_MIMETYPE,
  VIDEO_MIMETYPE,
} from 'helpers/FileHelpers';
import TrashIcon from './TrashIcon';
import {
  FilePreviewContainer,
  ImagePreview,
  RemoveFileContainer,
  VideoPreview,
} from '../components';
import PdfPreview from '../../../pdf-preview';
import TxtPreview from './TxtPreview';
import { useSelector } from 'react-redux';
import { selectIsCelebrateModalOpen } from 'modules/modal/selectors';

type FilePreviewProps = HTMLAttributes<HTMLDivElement> & {
  file?: { uri: string; type: string; name: string };
  removeFile?: () => void;
  disabled?: boolean;
};

const FilePreview = ({
  file,
  removeFile = () => null,
  disabled,
}: FilePreviewProps) => {
  const vidRef = useRef<HTMLVideoElement>(null);

  const isCelebrateModalOpen = useSelector(selectIsCelebrateModalOpen);

  const renderPreview = useCallback(() => {
    if (!file) {
      return null;
    }

    if (file.type === IMAGE_MIMETYPE) {
      return <ImagePreview src={file.uri} />;
    }

    if (file.type === PDF_MIMETYPE) {
      return <PdfPreview file={file} />;
    }

    if (file.type === AUDIO_MIMETYPE) {
      return <audio src={file.uri} controls />;
    }

    if (file.type === VIDEO_MIMETYPE) {
      return <VideoPreview ref={vidRef} src={file.uri} controls autoPlay />;
    }

    if (file.type === TEXT_MIMETYPE) {
      return <TxtPreview file={file} />;
    }

    return <div>This file type is not supported yet.</div>;
  }, [file]);

  useEffect(() => {
    isCelebrateModalOpen && vidRef.current && vidRef.current.pause();
  }, [isCelebrateModalOpen, vidRef]);

  return file && file.type && file.uri ? (
    <FilePreviewContainer>
      {renderPreview()}
      {disabled ? null : (
        <RemoveFileContainer onClick={removeFile}>
          <TrashIcon />
        </RemoveFileContainer>
      )}
    </FilePreviewContainer>
  ) : null;
};

export default FilePreview;
