import React, {
  ReactNode,
  useCallback,
  useRef,
  useState,
  MouseEvent,
  useEffect,
} from 'react';

import { DraggableCarouselContainer } from './components';

type DraggableCarouselProps<T> = {
  items: T[];
  renderItem: (item: T) => ReactNode;
  initialScrollPosition?: number;
};

const DraggableCarousel = <T,>({
  items,
  renderItem,
  initialScrollPosition = 0,
}: DraggableCarouselProps<T>) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [mouseDownX, setMouseDownX] = useState(0);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseUp = useCallback(() => {
    setIsScrolling(false);
  }, []);

  const handleMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
    setScrollPosition(itemContainerRef.current?.scrollLeft ?? 0);
    setIsScrolling(true);
    setMouseDownX(e.clientX);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (isScrolling && itemContainerRef.current) {
        itemContainerRef.current.scrollLeft =
          scrollPosition + mouseDownX - e.clientX;
      }
    },
    [isScrolling, itemContainerRef, mouseDownX, scrollPosition],
  );

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  useEffect(() => {
    if (itemContainerRef.current) {
      itemContainerRef.current.scrollLeft = initialScrollPosition;
    }
  }, [initialScrollPosition]);

  return (
    <DraggableCarouselContainer
      ref={itemContainerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}>
      {items.map((item) => renderItem(item))}
    </DraggableCarouselContainer>
  );
};

export default DraggableCarousel;
