import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlaylistBackground, PlayListCardsPanel } from '../components';
import localizedStrings from 'localization';
import {
  selectShownPlaylistTabs,
  selectAvailable,
  selectPlaylistItems,
  selectPlaylistItemsSelected,
} from './selectors';
import { AppDispatch } from 'redux/typings';
import { updatePlaylistFilter } from './actions';
import { Artifact, PLAYLIST_FILTERS } from './typings';
import PlaylistModal from 'modules/modal/PlaylistModal';
import ConfirmModal from 'modules/modal/ConfirmModal';
import PlaylistCard from 'modules/modal/PlaylistCard';
import { EmptyPlaylistCards } from 'components/empty-playlist-cards';
import { VerticalNavPlaylistContent } from 'components/vertical-navbar/VerticalNavPlaylistContent';

const Playlist = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState<PLAYLIST_FILTERS>(
    PLAYLIST_FILTERS.ALL,
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);
  const [selectedArtifact, setSelectedArtifact] = useState<
    Artifact | undefined
  >(undefined);

  const shownPlaylistTabs = useSelector(selectShownPlaylistTabs);

  const playlistTabItems = useSelector(selectPlaylistItems(selectedTab));
  const playlistTabItemsSelected = useSelector(
    selectPlaylistItemsSelected(selectedTab),
  );

  const showAvailableItems = useSelector(selectAvailable);

  const handleNavItemClick = useCallback(
    (name: string) => {
      setSelectedTab(name as PLAYLIST_FILTERS);
      dispatch(updatePlaylistFilter(name as PLAYLIST_FILTERS));
    },
    [dispatch],
  );

  const openPlaylistModal = useCallback(() => {
    setPlaylistModalOpen(true);
  }, []);
  const closePlaylistModal = useCallback(() => {
    setPlaylistModalOpen(false);
  }, []);
  const openConfirmModal = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);
  const closeConfrimModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const handlePlaylistCardClick = useCallback(
    (artifact: Artifact) => {
      setSelectedArtifact(artifact);
      if (artifact.type === 'reading') {
        openPlaylistModal();
      } else {
        window.open(artifact?.link);
        if (!artifact?.completed) {
          openConfirmModal();
        }
      }
    },
    [openConfirmModal, openPlaylistModal],
  );

  const handlePlaylistModalClose = useCallback(
    (artifact: Artifact | undefined) => {
      closePlaylistModal();
      if (!artifact?.completed) {
        openConfirmModal();
      }
    },
    [closePlaylistModal, openConfirmModal],
  );

  return (
    <PlaylistBackground>
      <PlaylistModal
        open={playlistModalOpen}
        close={handlePlaylistModalClose}
        artifact={selectedArtifact}
      />
      <ConfirmModal
        open={confirmModalOpen}
        close={closeConfrimModal}
        artifact={selectedArtifact}
      />
      <VerticalNavPlaylistContent
        title={localizedStrings.onTheGoPlaylist}
        items={shownPlaylistTabs}
        selectedItem={selectedTab}
        onItemClick={handleNavItemClick}
      />

      <PlayListCardsPanel>
        {showAvailableItems ? (
          playlistTabItems?.map((playlistArtifact: Artifact, index: number) => (
            <PlaylistCard
              key={playlistArtifact.id + index}
              artifact={playlistArtifact}
              noMarginBottom={index >= playlistTabItems.length}
              onPlaylistCardClick={handlePlaylistCardClick}
            />
          ))
        ) : (
          <>
            {playlistTabItemsSelected.length ? (
              playlistTabItemsSelected?.map(
                (playlistArtifact: Artifact, index: number) => (
                  <PlaylistCard
                    key={playlistArtifact.id + index}
                    artifact={playlistArtifact}
                    noMarginBottom={index >= playlistTabItemsSelected.length}
                    onPlaylistCardClick={handlePlaylistCardClick}
                  />
                ),
              )
            ) : (
              <EmptyPlaylistCards />
            )}
          </>
        )}
      </PlayListCardsPanel>
    </PlaylistBackground>
  );
};

export default Playlist;
