import React from 'react';

import Modal from 'components/modal';
import { ButtonContainer, SaveButton } from 'common/CommonComponents';
import localizedStrings from 'localization';

type MadlibAppletModalProps = {
  editableMadlibSentence?: React.ReactNode;
  modalTitle?: React.ReactNode;
  isModalOpen?: boolean;
  closeModal: () => void;
};

const MadlibAppletModal = ({
  editableMadlibSentence,
  modalTitle,
  isModalOpen = false,
  closeModal,
}: MadlibAppletModalProps) => {
  return (
    <Modal
      open={isModalOpen}
      closeModal={closeModal}>
      {modalTitle}
      {editableMadlibSentence}
      <ButtonContainer>
        <SaveButton onClick={closeModal}>{localizedStrings.save}</SaveButton>
      </ButtonContainer>
    </Modal>
  );
};

export default MadlibAppletModal;
