import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document } from '@contentful/rich-text-types';

import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  updateBranchFile,
  updateBranchDescription,
} from 'modules/applet-upload/actions';
import { selectBranchInformation } from 'modules/applet-upload/selectors';
import AppUpload, { FileType } from '../app-upload';
import { DecisionTreeInput } from '../components';

type BranchProps = {
  id?: string;
  appletId: string;
  fileId: string;
  type: 'any' | 'image' | 'video';
  prompt: Document;
  fileOrder: number;
  placeholder?: string;
};

const Branch = ({
  id,
  appletId,
  fileId,
  type,
  prompt,
  fileOrder,
  placeholder,
}: BranchProps) => {
  const dispatch = useDispatch();

  const appletUploadFileInformation = useSelector(
    selectBranchInformation(appletId, fileId),
  );

  const renderPrompt = useContentfulRenderer(prompt, {
    additionalStyles: { paragraph: { color: '#8E8E93' } },
  });

  const allowedFileType = useMemo(() => {
    if (type === 'image') {
      return FileType.images;
    }
    if (type === 'video') {
      return FileType.videos;
    }
    return FileType.all;
  }, [type]);

  const handleChangeFile = useCallback(
    (file: any) => {
      dispatch(
        updateBranchFile({
          applet: {
            id,
            appletId,
            fileId,
            file: file ?? null,
            fileOrder,
            fileUploaded: false,
          },
          modified: true,
        }),
      );
    },
    [id, appletId, dispatch, fileId, fileOrder],
  );

  const onChangeText = useCallback(
    (evt) => {
      dispatch(
        updateBranchDescription({
          applet: {
            id,
            appletId,
            fileId,
            description: evt.target.value,
            fileOrder,
          },
          modified: true,
        }),
      );
    },
    [id, appletId, dispatch, fileId, fileOrder],
  );

  return (
    <>
      {renderPrompt}
      <AppUpload
        fileType={allowedFileType}
        value={appletUploadFileInformation?.file}
        onChangeFile={handleChangeFile}
        disabled={false}
      />
      <DecisionTreeInput
        value={appletUploadFileInformation?.description}
        placeholder={placeholder}
        onChange={onChangeText}
      />
    </>
  );
};

export default Branch;
