import React from 'react';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import { Link, LinkProps } from 'components/contentful-renders/components';

type HyperlinkProps = BlockNodeProps & LinkProps;

const Hyperlink = ({ node, children, ...linkProps }: HyperlinkProps) => {
  return (
    <Link
      {...linkProps}
      href={node.data.uri}
      target="_blank"
      rel="noreferrer noopener">
      {children}
    </Link>
  );
};

export default Hyperlink;
