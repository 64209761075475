import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Quill from 'quill';

import { AppDispatch, RootState } from 'redux/typings';
import {
  cleanRichTextAsset,
  uploadAssetForRichText,
} from 'modules/applet-upload/actions';
import { selectRichTextAsset } from 'modules/applet-upload/selectors';
import {
  FormContent,
  FormField,
  ToolbarButton,
  InsertTableButton,
  InsertTableModal,
  ModalText,
  RichtTextContainer,
} from './components';
import { ModalContainer } from 'components/modal/components';
import undoArrow from 'assets/icons/undo-arrow.webp';
import redoArrow from 'assets/icons/redo-arrow.webp';
import tableIcon from 'assets/icons/Table.webp';
import LoadingSpinner from 'components/loading-spinner';
import ImageUploadButton from './ImageUploadButton';
import * as StatusSelectors from 'modules/status/selectors';
import { getFileObject } from 'helpers/FileHelpers';
import {
  databaseToRichText,
  RichTextToDatabase,
} from 'helpers/RichTextHelpers';
import {
  selectIsEditableContentSubmitted,
  selectSelectedStepIsCelebrate,
} from 'modules/loading-screen/contents/selectors';

const QuillBetterTable = require('quill-better-table');

type RichTextAppletProps = {
  sendButton?: ReactNode;
  uploadPrompt?: ReactNode;
  disabled?: boolean;
  onContentChange?: (content: string) => void;
  initialContent?: string;
  onFirstEdition?: (value: boolean) => void;
  firstEdition?: boolean;
};

const RichTextApplet = ({
  sendButton,
  uploadPrompt,
  disabled,
  onContentChange,
  initialContent,
  onFirstEdition,
  firstEdition,
}: RichTextAppletProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmittingAsset, setIsSubmittingAsset] = useState(false);
  const [showInsertTableModal, setShowInsertTableModal] = useState(false);
  const [table, setTable] = useState();
  const [editor, setEditor] = useState<Quill>();
  const [hasInitiallySetContent, setHasInitiallySetContent] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const uploadedAsset = useSelector(selectRichTextAsset);
  const hasSubmittedAsset = useSelector((state: RootState) =>
    StatusSelectors.wasFulfilledSelector(
      state,
      uploadAssetForRichText.typePrefix,
    ),
  );

  const isCelebrate = useSelector(selectSelectedStepIsCelebrate);
  const isEditableContentSubmitted = useSelector(
    selectIsEditableContentSubmitted,
  );

  const undo = useCallback(() => {
    (editor as any).history.undo();
  }, [editor]);

  const redo = useCallback(() => {
    (editor as any).history.redo();
  }, [editor]);

  const openInsertTableModal = useCallback(() => {
    setShowInsertTableModal(true);
  }, []);

  const insertTable = useCallback(
    (values: { rows: ''; columns: '' }, { resetForm }) => {
      editor?.focus();
      (table as any).insertTable(Number(values.rows), Number(values.columns));
      setShowInsertTableModal(false);
      resetForm();
    },
    [editor, table],
  );

  const handleImageChange = useCallback(
    (image: File) => {
      const file = getFileObject({
        name: image.name,
        type: image.type,
        uri: URL.createObjectURL(image),
      });
      if (file) {
        setIsSubmittingAsset(true);
        dispatch(uploadAssetForRichText({ file }));
      }
    },
    [dispatch, setIsSubmittingAsset],
  );

  useEffect(() => {
    if (!editor && editorRef.current && toolbarRef.current) {
      Quill.register({
        'modules/better-table': QuillBetterTable,
      });
      setEditor(
        new Quill(editorRef.current, {
          modules: {
            toolbar: disabled ? false : { container: toolbarRef.current },
            table: false,
            'better-table': {
              operationMenu: {
                items: {},
              },
            },
            keyboard: {
              bindings: QuillBetterTable.keyboardBindings
            },
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true,
            },
          },
          theme: 'snow',
          debug: 'false'
        }),
      );
    }
  }, [disabled, editor]);

  useEffect(() => {
    if (editor) {
      editor.on('text-change', function () {
        if (!firstEdition) {
          onFirstEdition?.(true);
        }
      });

      editor.root.addEventListener('blur', () => {
        const content = RichTextToDatabase(editor.root.innerHTML);
        onContentChange?.(content);
      });

      editor.enable(!disabled);
      setTable(editor.getModule('better-table'));
      editor.root.addEventListener('blur', () => {
        const content = RichTextToDatabase(editor.root.innerHTML);
        onContentChange?.(content);
      });

      if (initialContent && !hasInitiallySetContent) {
        setHasInitiallySetContent(true);
        const value = databaseToRichText(initialContent);
        const delta = editor.clipboard.convert({ html: value });
        editor.setContents(delta, 'silent');
      }
    }
  }, [
    disabled,
    editor,
    hasInitiallySetContent,
    initialContent,
    onContentChange,
    firstEdition,
    onFirstEdition,
  ]);

  useEffect(() => {
    if (isSubmittingAsset && hasSubmittedAsset && uploadedAsset) {
      setIsSubmittingAsset(false);
      if (editor) {
        const selection = editor.getSelection(true);
        editor.insertEmbed(selection.index, 'image', uploadedAsset.fileUrl);
      }
      dispatch(cleanRichTextAsset());
    }
  }, [
    dispatch,
    hasSubmittedAsset,
    uploadedAsset,
    setIsSubmittingAsset,
    editor,
    isSubmittingAsset,
  ]);

  return (
    <>
      {!isCelebrate ? uploadPrompt : null}
      <RichtTextContainer disabled={disabled}>
        <ModalContainer visible={isSubmittingAsset}>
          <ModalText>Uploading Asset...</ModalText>
          <LoadingSpinner size={40} marginLeft={20} />
        </ModalContainer>
        <div
          ref={toolbarRef}
          style={{
            pointerEvents: disabled ? 'none' : 'auto',
            display: disabled || isEditableContentSubmitted ? 'none' : 'block',
          }}>
          <span className="ql-formats">
            <select name="asset" className="ql-header" />
          </span>
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-blockquote"></button>
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-indent" value="-1"></button>
            <button className="ql-indent" value="+1"></button>
          </span>
          <span className="ql-formats">
            <select name="asset-aling" className="ql-align"></select>
          </span>
          <span className="ql-formats">
            <button className="ql-link"></button>
            <ImageUploadButton onImageChange={handleImageChange} />
          </span>
          <span className="ql-formats">
            <ToolbarButton onClick={undo}>
              <img src={undoArrow} alt="undo" />
            </ToolbarButton>
            <ToolbarButton onClick={redo}>
              <img src={redoArrow} alt="redo" />
            </ToolbarButton>
          </span>
          <span className="ql-formats">
            <ToolbarButton onClick={openInsertTableModal}>
              <img src={tableIcon} alt="table" />
            </ToolbarButton>
            <InsertTableModal
              visible={showInsertTableModal}
              className="tableModal">
              <Formik
                initialValues={{ rows: '', columns: '' }}
                onSubmit={insertTable}>
                <FormContent>
                  <FormField
                    name="rows"
                    placeholder="Rows"
                    type="number"
                    min="1"
                    max="10"
                  />
                  <FormField
                    name="columns"
                    placeholder="Columns"
                    type="number"
                    min="1"
                    max="10"
                  />
                  <InsertTableButton type="submit">
                    Insert table
                  </InsertTableButton>
                </FormContent>
              </Formik>
            </InsertTableModal>
          </span>
        </div>
        <div ref={editorRef}></div>
      </RichtTextContainer>
      {sendButton}
    </>
  );
};

export default RichTextApplet;
