import React, { ReactNode } from 'react';

import {
  MadlibAppletContainer,
  MadlibAppletTextContainer,
  MadlibEditIconContainer,
} from '../components';
import theme from 'utils/theme';
import EditIcon from 'components/icons/EditIcon';

type MadlibAppletProps = {
  madlibSentence?: ReactNode;
  uploadPrompt?: ReactNode;
  shareButton?: ReactNode;
  onEditPress?: () => void;
  disabled?: boolean;
};

const MadlibApplet = ({
  madlibSentence,
  uploadPrompt,
  shareButton,
  onEditPress,
  disabled,
}: MadlibAppletProps) => {
  return (
    <>
      {uploadPrompt}
      <MadlibAppletContainer>
        <MadlibAppletTextContainer>{madlibSentence}</MadlibAppletTextContainer>
        {onEditPress && !disabled ? (
          <MadlibEditIconContainer onClick={onEditPress}>
            <EditIcon
              width={theme.typography.large32}
              height={theme.typography.large32}
            />
          </MadlibEditIconContainer>
        ) : null}
      </MadlibAppletContainer>
      {shareButton}
    </>
  );
};

export default MadlibApplet;
